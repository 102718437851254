import * as React from "react";
import { createRoot } from "react-dom/client";
import { Scaffold } from "../components/scaffold";
import { getPortalSetting } from "../helper/consts";
import { Routes } from "../helper/routes";

function SurveyApp(props: any) {
  return (
    <Scaffold title="">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-8 mt-md-4 col-lg-7 col-xl-6 col-xxl-5 mt-lg-5">
          <p className="display-6 mb-4">
            View Nettskjema submissions for {getPortalSetting().sinkName}
          </p>
          {getPortalSetting().welcomeMessage}
          <a className="btn btn-primary btn-lg mt-4" href={Routes.Home}>
            Show forms
          </a>
        </div>
      </div>
    </Scaffold>
  );
}

const rootElement = document.getElementById("app");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<SurveyApp />);
}
